.comments-cell {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;

  & .btn {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &__send {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
  }
}