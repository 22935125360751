.comments-table {
  width: 100%;

  &__img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: auto;
      cursor: pointer;
    }

    img:hover {
      width: 15%;
      position: fixed;
      margin-bottom: 300px;
      z-index: 2;
    }
  }

  &__title-wrapper {
    text-align: start;
    padding: 0 8px;
    color: #3c92dc;

    & a {
      text-align: start;
      // cursor: pointer;
    }
  }

  .k-table-td {
    text-align: center;
  }
}

.anchor {
  position: absolute;
  left: 10vw;
  top: 20vh;
}

.comments-block {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .button {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  &__btn-block {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__comments {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    &__input {
      display: flex;
      flex-direction: row;
    }
  }
}