.hovered-image-container {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-40%, -40%);
  z-index: 1000;
}

.hovered-image {
  // width: 40%;
  width: 250px;
  height: auto;
}

// .inform-table__img-wrapper {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 35px;
//   position: relative;
//   z-index: 1;
// }

// .inform-table__img-wrapper img {
//   position: absolute;
//   width: 100%;
//   height: auto;
//   cursor: pointer;
//   z-index: 99;
// }

.platform-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25% !important;
  z-index: 100;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
}