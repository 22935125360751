.home-header-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  & .statistic-block {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;

    & p {
      padding: 0;
      margin: 0;

      & span {
        font-weight: bold;
      }
    }
  }

  & .data-picker-block {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 30px;
  }
}

@media (max-width: 980px) {
  .home-header-block {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .home-header-block {
    & .data-picker-block {
      flex-wrap: wrap;
      height: auto
    }
  }
}