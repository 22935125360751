.date-picker {
  display: flex;
  align-items: center;

  & .k-daterangepicker {
    & .k-floating-label-container {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 0.5rem;
      padding: 0;

      & .k-label {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 1rem;
        position: relative;
      }
    }
  }
}