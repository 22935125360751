.img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: auto;
      cursor: pointer;
    }

    img:hover {
      width: 15%;
      position: fixed;
      margin-bottom: 300px;
      z-index: 2;
    }
}