.header-title {
  font-size: 18px;
  margin: 0;
  cursor: pointer;
}

.header-info-section {
  ul {
    font-size: 14px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
      margin: 0 10px;
    }

    li:hover {
      cursor: pointer;
      color: #84cef1;
    }
  }
}