$hover-offset: 20px;

.inform-table {
  width: 100%;

  &__img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    // position: relative;
    z-index: 1;

    img {
      position: absolute;
      width: 100%;
      height: auto;
      cursor: pointer;
      z-index: 99;
    }
  }

  &__title-wrapper {
    text-align: start;
    padding: 0 8px;
    color: #3c92dc;

    & a {
      text-align: start;
    }
  }

  &__comments-wrapper {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #3c92dc;
      cursor: pointer;
    }
  }

  .k-table-td {
    text-align: center;
  }
}

.anchor {
  position: absolute;
  left: 10vw;
  top: 20vh;
}