.main-layout {
  min-height: 100vh;

  &-wrapper {
    .header {
      padding: 0;
      margin: 20px;
      background-color: #F5F5F5;
    }

    .content {
      margin: 20px;
    }
  }
}