.user-list {
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 0.5rem;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & h2 {
      margin: 0;
    }

    &__btn-block {
      display: flex;
      gap: 0.5rem;
    }
  }
}