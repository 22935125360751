.charts-block {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: row;

    & .elem {
      width: 100%;
    }
  }
}

@media (max-width: 1000px) {
  .charts-block {
    &__container {
      flex-direction: column;

      & .elem {
        width: 100%;
      }
    }
  }
}